import { GroupuiHeadline, GroupuiText, GroupuiTable, GroupuiTableCell } from '@group-ui/group-ui-react';

export default function Page(){

	return (
		<div className="page">
            {/* banner */}
            <div className="ui-banner">
                <div className="media">
                    <video src={`${process.env.PUBLIC_URL}/assets/video/VWG_Layers_green_blue.mp4`} autoPlay muted loop playsInline/>
                </div>
                <div className="ui-inner">
                    <GroupuiHeadline heading="h2" fontVariant="TheGroupHEAD-Light" marketing={true}>Whistleblowers.</GroupuiHeadline>
                    <GroupuiText>Discover how our Whistleblower System works below.</GroupuiText>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser">
                <div className="ui-inner">
                    <div className="ui-columns">
                        <div className="ui-column">
                            <div className="media">
                                <img src={`${process.env.PUBLIC_URL}/assets/img/editorial/conduct.jpg`} alt=""/>
                            </div>
                        </div>
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>The confidential Whistleblower System.</GroupuiHeadline>
                            <GroupuiText>Complying with statutory regulations and internal rules, and the principles laid down in our Code of Conduct and the Code of Conduct for Business Partners, is top priority at Volkswagen Group Middle East. The success of our company is based on Integrity and Compliance. To meet these standards, it is important to learn of potential employee or supplier misconduct and to put a stop to it. Therefore, we entrusted the Central Investigation Office to operate an independent, impartial and confidential Whistleblower System on our behalf.</GroupuiText>
                        </div>
                    </div>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser ui-deep-blue-bg">
                <div className="ui-inner">
                    <div className="ui-columns">
                        <div className="ui-column">
                            <div className="media">
                                <img src={`${process.env.PUBLIC_URL}/assets/img/compliance/whistleblower-chart.png`} alt=""/>
                            </div>
                            <br/><br/>
                            <GroupuiText>A key pillar of our Whistleblower System is the principle of procedural fairness. It also guarantees the greatest possible protection for whistleblowers, Persons Implicated and employees contributing to the investigation of reported misconduct. This also includes offering opportunities for anonymous reporting and communication. We assure not to perform any steps in order to identify anonymous whistleblowers, who do not misuse our Whistleblower System. Retaliation of whistleblowers and all persons who contribute to investigations at Volkswagen Group Middle East will not be tolerated. Persons implicated are presumed innocent until the violation is proven. Investigations will be conducted with the utmost confidentiality. The information will be processed in a fair, fast and protected process.</GroupuiText>
                        </div>
                    </div>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser">
                <div className="ui-inner">
                    <div className="ui-columns">
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>How do we process your report?</GroupuiHeadline>
                            <GroupuiText>The qualified and experienced colleagues at the Investigation Office examine every report for potential misconduct by a Volkswagen Group employee thoroughly and follow it up systematically. First, you will get a confirmation of receipt. The Investigation Office then assesses your report. This includes gathering facts particularly from the whistleblower. Only if this initial evaluation shows grounds for suspicion of a violation an investigation by a dedicated Investigating Unit will be started. Afterwards, the results of the investigation will be assessed by the Investigation Office and appropriate measures will be recommended. Information about the status* and the outcome of the procedure will be given to you without undue delay.
                            <br/><br/>
                            Potential violations of the Code of Conduct for Business Partners by suppliers, including serious risks and violations of human rights and environment by direct and indirect suppliers, can also be reported to the Investigation Office - as well as reports requiring otherwise immediate action. The Investigation Office will inform the responsible departments, who will process the issue accordingly. This particularly includes taking the necessary measures to minimize or end violations and/or risks.
                            <br/><br/>
                            * The processing time varies depending on the subject of the procedure Find more information on the procedural principles of our whistleblower system here
                            <br/><br/>
                            Do you have any concern or feedback regarding an Volkswagen Group Middle East product or service?
                            <br/><br/>
                            If you have any questions or inquiries regarding your new or used vehicle | feedback or complaints about services provided by Volkswagen Group Middle East please <a href="/contact" className="text-link">get in contact</a> with us. We kindly ask for your understanding that the Whistleblower System unfortunately cannot process customer complaints.</GroupuiText>
                        </div>
                    </div>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser ui-deep-blue-bg">
                <div className="ui-inner">
                    <div className="ui-columns">
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>Where do I send my report?</GroupuiHeadline>
                            <GroupuiText>
                            24/7 Hotline<br/>
                            You can make a report at 365 days, 24 hours, using the international toll-free number: <a href="tel:+800 444 46300" className="text-link">+800 444 46300.</a>* If your local telephone provider does not support the toll-free service, you can call the following chargeable number: <a href="tel:+49 5361 946300" className="text-link">+49 5361 946300.</a>
                            <br/><br/>
                            *Depending on the country you are calling from it is possible that the international toll-free hotline is not available since some telephone network providers do not support the service. If so, please use the offered chargeable number or your country specific number.
                            <br/><br/>
                            <b>Country specific numbers</b>
                            </GroupuiText>
                            <GroupuiTable columns="3" sticky={false} header={true} widths="auto auto auto">
                                <GroupuiTableCell>Country</GroupuiTableCell>
                                <GroupuiTableCell>Toll-free number</GroupuiTableCell>
                                <GroupuiTableCell>Local number</GroupuiTableCell>

                                <GroupuiTableCell>Brazil</GroupuiTableCell>
                                <GroupuiTableCell><a href="tel:0800-5912743" className="text-link">0800-5912743</a></GroupuiTableCell>
                                <GroupuiTableCell><a href="tel:021-23911381" className="text-link">021-23911381</a></GroupuiTableCell>

                                <GroupuiTableCell>Mexico</GroupuiTableCell>
                                <GroupuiTableCell><a href="tel:001-800-4610242" className="text-link">001-800-4610242</a></GroupuiTableCell>
                                <GroupuiTableCell><a href="tel:0155-71000355" className="text-link">0155-71000355</a></GroupuiTableCell>

                                <GroupuiTableCell>Slovak Republic</GroupuiTableCell>
                                <GroupuiTableCell><a href="tel:0800-002576" className="text-link">0800-002576</a></GroupuiTableCell>
                                <GroupuiTableCell><a href="tel:02-33325602" className="text-link">02-33325602</a></GroupuiTableCell>

                                <GroupuiTableCell>USA</GroupuiTableCell>
                                <GroupuiTableCell><a href="tel:833-6571574" className="text-link">833-6571574</a></GroupuiTableCell>
                                <GroupuiTableCell><a href="tel:908-2198092" className="text-link">908-2198092</a></GroupuiTableCell>

                                <GroupuiTableCell>South Africa</GroupuiTableCell>
                                <GroupuiTableCell><a href="tel:0800-994983" className="text-link">0800-994983</a></GroupuiTableCell>
                                <GroupuiTableCell><a href="tel:021-1003533" className="text-link">021-1003533</a></GroupuiTableCell>

                                <GroupuiTableCell>Malaysia</GroupuiTableCell>
                                <GroupuiTableCell><a href="tel:1-800-819523" className="text-link">1-800-819523</a></GroupuiTableCell>
                                <GroupuiTableCell><a href="tel:0154-600099" className="text-link">0154-600099</a></GroupuiTableCell>

                                <GroupuiTableCell>Argentina</GroupuiTableCell>
                                <GroupuiTableCell><a href="tel:0800-6662992" className="text-link">0800-6662992</a></GroupuiTableCell>
                                <GroupuiTableCell><a href="tel:011-52528632" className="text-link">011-52528632</a></GroupuiTableCell>

                                <GroupuiTableCell>Germany</GroupuiTableCell>
                                <GroupuiTableCell><a href="tel:0800-444-46300" className="text-link">0800-444-46300</a></GroupuiTableCell>
                                <GroupuiTableCell><a href="tel:05361-946300" className="text-link">05361-946300</a></GroupuiTableCell>
                            </GroupuiTable>
                            <br/><br/>
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>Online Reporting Channel</GroupuiHeadline>
                            <GroupuiText>
                                You have the option of using a web-based communication platform [Introduction (<a href="https://www.bkms-system.com/bkwebanon/report/clientInfo?cin=22vwgroup16&c=-1&language=eng" target="_blank" rel="noreferrer" className="text-link">bkms-system.com</a>)] to contact the Investigation Office in many languages. This system is confidential and technically secured.
                                <br/><br/>
                                Even if your preferred language is not offered in the reporting channel, you can use any language to submit your report. You can also contact the Investigation Office in any language via e-mail or mail.
                                <br/><br/>
                                <b>Central Investigation Office</b>
                                <br/><br/>
                                <b>Email</b><br/>
                                You can get in contact with the Investigation Office of the Volkswagen Group via e-mail: <a href="mailto:io@volkswagen.de" className="text-link">io@volkswagen.de</a>
                                <br/><br/>
                                <b>Postal address:</b><br/>
                                Central Investigation Office<br/>
                                Box 1717<br/>
                                38436 Wolfsburg (downtown)<br/>
                                Germany
                                <br/><br/>
                                <b>In person:</b><br/>
                                Central Investigation Office<br/>
                                Porschestrasse 1<br/>
                                38440 Wolfsburg (downtown)<br/>
                                Germany
                                <br/><br/>
                                Please make an appointment in advance by writing an e-mail to: <a href="mailto:io@volkswagen.de" className="text-link">io@volkswagen.de</a>.
                                <br/><br/>
                                <b>Ombudspersons</b><br/>
                                Volkswagen Group Middle East has appointed external lawyers to act as Ombudspersons. They advise on the Whistleblower System or ensure that reports from whistleblowers are forwarded anonymously to the Investigation Office if desired.
                                <br/><br/>
                                If you want to get in contact with the Ombudsperson you can find their contact details here: <a href="http://www.ombudsmen-of-volkswagen.com/" target="_blank" rel="noreferrer" className="text-link">http://www.ombudsmen-of-volkswagen.com/</a>
                                <br/><br/>
                                <b>Do you have further questions?</b>
                                <br/><br/>
                                Questions or suggestions for improvement concerning the Whistleblower System can also be addressed to the Investigation Office. Furthermore, our local Compliance Officer can also be addressed in all matters of the Whistleblower System via <a href="mailto:compliance@vwgme.com" className="text-link">compliance@vwgme.com</a>
                                <br/><br/>
                                <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}> Making a report to our Whistleblower System</GroupuiHeadline>
                                <GroupuiText>The Whistleblower System offers various channels to report potential employee misconduct that allow a swift review and reaction by our company if necessary.</GroupuiText>
                                <a href={`${process.env.PUBLIC_URL}/assets/docs/whistleblowers.pdf`} className="cta">Download to submit report</a>
                            </GroupuiText>
                        </div>
                    </div>
                </div>
            </div>
		</div>
	);
}
